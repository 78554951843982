import { NavLink } from "@remix-run/react"
import { menuData } from "../constants/menuData"
import clsx from "clsx"
import { useContext } from "react"
import { xStateMenuContext } from "../contexts/MenuContext"

interface HeaderProps {
  currentPage: string
}

export function Header(props: HeaderProps) {
  const { currentPage } = props
  const { menuService } = useContext(xStateMenuContext)
  const { send } = menuService

  return (
    <header id="header">
      <nav aria-label="main navigation">
        <NavLink to="/" id="identity" data-testid="home-link">
          Renewing Your Mind
        </NavLink>
        {menuData.map((item) => (
          <NavLink
            key={item.label}
            to={item.url}
            className={clsx("item", {
              current: item.url === currentPage,
            })}
            data-testid={`header-${item.label}`}
          >
            {item.label}
          </NavLink>
        ))}
        <button
          id="menu-btn"
          onClick={() => send({ type: "TOGGLE_CONTENT" })}
          data-testid="menu-icon"
        >
          Menu
        </button>
      </nav>
    </header>
  )
}
